'use client'

import Image from "next/image"

export function generateMetadata({ params, searchParams }, parent) {
  return {
    title: 404,
  }
}
export default function GlobalError({ error, reset }) {
  return (
    <html>
      <body>
        <Image
          src={`/img/error/404-XL.jpg`}
          alt={`error 404`}
          title={`error 404`}
          width={1920}
          height={1080}
          style = {{
            width: `100%`,
            height: `100dvh`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            display: `flex`,
          }}
        />
      </body>
    </html>
  )
}
